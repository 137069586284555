@font-face {
    font-family: 'Myriad Pro';
    src: url('./source/MyriadPro-Regular.eot');
    src: url('./source/MyriadPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('./source/MyriadPro-Regular.woff2') format('woff2'),
    url('./source/MyriadPro-Regular.woff') format('woff'),
    url('./source/MyriadPro-Regular.ttf') format('truetype'),
    url('./source/MyriadPro-Regular.svg#MyriadPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('./source/MyriadPro-Semibold.eot');
    src: url('./source/MyriadPro-Semibold.eot?#iefix') format('embedded-opentype'),
    url('./source/MyriadPro-Semibold.woff2') format('woff2'),
    url('./source/MyriadPro-Semibold.woff') format('woff'),
    url('./source/MyriadPro-Semibold.ttf') format('truetype'),
    url('./source/MyriadPro-Semibold.svg#MyriadPro-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('./source/MyriadPro-Bold.eot');
    src: url('./source/MyriadPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('./source/MyriadPro-Bold.woff2') format('woff2'),
    url('./source/MyriadPro-Bold.woff') format('woff'),
    url('./source/MyriadPro-Bold.ttf') format('truetype'),
    url('./source/MyriadPro-Bold.svg#MyriadPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Verdana';
    src: url('./source/Verdana.eot');
    src: url('./source/Verdana.eot?#iefix') format('embedded-opentype'),
    url('./source/Verdana.woff2') format('woff2'),
    url('./source/Verdana.woff') format('woff'),
    url('./source/Verdana.ttf') format('truetype'),
    url('./source/Verdana.svg#Verdana') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Verdana';
    src: url('./source/Verdana-Bold.eot');
    src: url('./source/Verdana-Bold.eot?#iefix') format('embedded-opentype'),
    url('./source/Verdana-Bold.woff2') format('woff2'),
    url('./source/Verdana-Bold.woff') format('woff'),
    url('./source/Verdana-Bold.ttf') format('truetype'),
    url('./source/Verdana-Bold.svg#Verdana-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

body {
    font-family: 'Myriad Pro', serif;
}

/*
REACT FLAGS...
*/
.country-select {
    padding-bottom: 0;
}

.country-select button {
    height: 43px !important;
}

.country-select > button > span {
    width: 80%;
}
